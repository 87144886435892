import React from "react";
import { BrowserRouter as Router, Route, Switch } from "react-router-dom";
import ScrollToTop from "./components/hoComp/ScrollToTop";
import Annoucement from "./components/announcement/Announcement";
import Navbar from "./components/navbar/Navbar";
import Login from "./pages/login/Login";
import Register from "./pages/register/Register";
import Home from "./pages/Home";
import User from "./pages/user/User";
import Cart from "./pages/cart/Cart";
import Success from "./pages/success/Success";
import Product from "./pages/product/Product";
import ProductList from "./pages/product/ProductList";
import Footer from "./components/footer/Footer";

import AuthRoute from "./util/AuthRoute";
import UserRoute from "./util/UserRoute";

const App = () => {
  return (
    <div className="app">
      <Router>
        <ScrollToTop />
        <Annoucement />
        <Navbar />
        <Switch>
          <Route path="/" exact>
            <Home />
          </Route>
          <Route path="/product/:id" exact>
            <Product />
          </Route>
          <Route path="/products" exact>
            <ProductList />
          </Route>
          <Route path="/products/:category" exact>
            <ProductList />
          </Route>
          <AuthRoute path="/login" exact>
            <Login />
          </AuthRoute>
          <AuthRoute path="/register" exact>
            <Register />
          </AuthRoute>
          <UserRoute path="/cart" exact>
            <Cart />
          </UserRoute>
          <UserRoute path="/success" exact>
            <Success />
          </UserRoute>
          <UserRoute path="/profile/:userId" exact>
            <User />
          </UserRoute>
        </Switch>
        <Footer />
      </Router>
    </div>
  );
};

export default App;
