import { useEffect, useState } from "react";
import { useSelector } from "react-redux";
import {
  Wrapper,
  Container,
  TitleContainer,
  TitleDiv,
  Title,
  Icon,
  ProfileTop,
  TopLeftContainer,
  TopRightContainer,
  TopLeft,
  TopLeftTitle,
  TopRight,
  InfoContainer,
  InfoTitle,
  InfoDesc,
  ProfileBottom,
  TableDiv,
  Table,
  TableHead,
  TableHeading,
  TableRow,
  TableBody,
  TableData,
} from "./UserElements";
import { configHeaders, userRequest } from "../../requestMethods";

const User = () => {
  const user = useSelector((state) => state.auth?.currentUser);
  const token = useSelector((state) => state.auth?.currentUser?.accessToken);
  const [userOrders, setUserOrders] = useState([]);

  useEffect(() => {
    const getUserOrders = async () => {
      try {
        const res = await userRequest.get(
          `/orders/find/${user._id}`,
          configHeaders(token)
        );
        setUserOrders(res.data);
      } catch (error) {
        console.error(error);
      }
    };

    getUserOrders();
  }, [user._id, token]);

  return (
    <Wrapper>
      <Container>
        <>
          <TitleContainer>
            <TitleDiv>
              <Icon>
                <i className="fas fa-user"></i>
              </Icon>
              <Title>Perfil</Title>
            </TitleDiv>
          </TitleContainer>
          <ProfileTop>
            <TopLeftContainer>
              <TopLeft>
                <TopLeftTitle>Informação do Utilizador</TopLeftTitle>
                <InfoContainer>
                  <InfoTitle>Nome do Utilizador:</InfoTitle>
                  <InfoDesc>{user?.username}</InfoDesc>
                </InfoContainer>

                <InfoContainer>
                  <InfoTitle>Email:</InfoTitle>
                  <InfoDesc>{user?.email}</InfoDesc>
                </InfoContainer>
              </TopLeft>
            </TopLeftContainer>
            <TopRightContainer>
              <TopRight>
                <TopLeftTitle>Informação da Morada</TopLeftTitle>
                {userOrders.map(
                  (order, i) =>
                    i === 0 && (
                      <div key={order._id}>
                        <InfoContainer>
                          <InfoTitle>Pais:</InfoTitle>
                          <InfoDesc>{order.address.country}</InfoDesc>
                        </InfoContainer>

                        <InfoContainer>
                          <InfoTitle>Cidade:</InfoTitle>
                          <InfoDesc>{order.address.city}</InfoDesc>
                        </InfoContainer>

                        <InfoContainer>
                          <InfoTitle>Morada:</InfoTitle>
                          <InfoDesc>{order.address.line1}</InfoDesc>
                        </InfoContainer>

                        <InfoContainer>
                          <InfoTitle>Codigo Postal:</InfoTitle>
                          <InfoDesc>{order.address.postal_code}</InfoDesc>
                        </InfoContainer>
                      </div>
                    )
                )}
              </TopRight>
            </TopRightContainer>
          </ProfileTop>

          <ProfileBottom>
            <TopLeftTitle>My Orders</TopLeftTitle>
            <>
              {userOrders.length === 0 ? (
                <h4>No order to show</h4>
              ) : (
                <TableDiv>
                  <Table>
                    <TableHead>
                      <TableRow>
                        <TableHeading>ID</TableHeading>
                        <TableHeading>Receipt</TableHeading>
                        <TableHeading>DATE</TableHeading>
                        <TableHeading>TOTAL</TableHeading>
                        <TableHeading>PAID</TableHeading>
                      </TableRow>
                    </TableHead>
                    <TableBody>
                      {userOrders.map((order) => (
                        <TableRow key={order._id}>
                          <TableData>{order._id}</TableData>
                          <TableData>
                            <a
                              href={order.receipt}
                              style={{ textDecoration: "none" }}
                              target="_blank"
                              rel="noreferrer"
                            >
                              Ver Recibo
                            </a>
                          </TableData>
                          <TableData>
                            {new Date(order.createdAt).toDateString()}
                          </TableData>
                          <TableData>$ {order.amount}</TableData>
                          <TableData>
                            {order.status === "succeeded" ? (
                              <p
                                style={{
                                  backgroundColor: "green",
                                  color: "white",
                                  padding: "5px",
                                  borderRadius: "3px",
                                }}
                              >
                                Pago
                              </p>
                            ) : (
                              <p
                                style={{
                                  backgroundColor: "crimson",
                                  color: "white",
                                  padding: "5px",
                                  borderRadius: "3px",
                                }}
                              >
                                Not Paid
                              </p>
                            )}
                          </TableData>
                        </TableRow>
                      ))}
                    </TableBody>
                  </Table>
                </TableDiv>
              )}
            </>
          </ProfileBottom>
        </>
      </Container>
    </Wrapper>
  );
};

export default User;
