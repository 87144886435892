import React from "react";
import { AnnouncementContainer } from "./AnnouncementElement";

const Annoucement = () => {
  return (
    <AnnouncementContainer>
      Super Deal! Free Shipping on Orders Over $50!
    </AnnouncementContainer>
  );
};

export default Annoucement;
