import { createSlice } from "@reduxjs/toolkit";

const cartSlice = createSlice({
  name: "cart",
  initialState: {
    products: [],
  },
  reducers: {
    addProductToCart: (state, action) => {
      const item = state.products.find((item) => item.id === action.payload.id);
      if (item) {
        item.quantity += action.payload.quantity;
      } else {
        state.products.push(action.payload);
      }
    },
    incrementQuantity: (state, action) => {
      const item = state.products.find((item) => item._id === action.payload);
      item.quantity++;
    },
    decrementQuantity: (state, action) => {
      const item = state.products.find((item) => item._id === action.payload);
      if (item.quantity === 1) {
        item.quantity = 1;
      } else {
        item.quantity--;
      }
    },
    deleteProductFromCart: (state, action) => {
      const removeItem = state.products.filter(
        (item) => item._id !== action.payload
      );
      state.products = removeItem;
    },
    clearCart: (state) => {
      state.products = [];
    },
    orderStart: (state) => {
      state.isFetching = true;
    },
    orderSuccess: (state, action) => {
      state.isFetching = false;
      state.currentUser = action.payload;
    },
    orderFailure: (state) => {
      state.isFetching = false;
      state.error = true;
    },
  },
});

export const {
  addProductToCart,
  incrementQuantity,
  decrementQuantity,
  deleteProductFromCart,
  clearCart,
  orderStart,
  orderSuccess,
  orderFailure,
} = cartSlice.actions;
export default cartSlice.reducer;
