import styled from "styled-components";
import { Link as LinkR } from "react-router-dom";
import { mobile } from "../../responsive";

export const NavContainer = styled.div`
  height: 100px;
  ${mobile({ height: "80px" })}
`;
export const NavWrapper = styled.div`
  padding: 0px 20px;
  display: flex;
  justify-content: space-between;
  align-items: center;
  ${mobile({ padding: "20px 0px" })}
`;
//left
export const Left = styled.div`
  flex: 1;
  display: flex;
  align-items: center;
`;
export const MobileIcon = styled.div`
  display: none;
  ${mobile({
    display: "flex",
    marginLeft: "10px",
  })}
`;

export const SearchContainer = styled.div`
  border: 1px solid lightgray;
  display: flex;
  align-items: center;
  margin-left: 25px;
  padding: 5px;
`;

export const Input = styled.input`
  border: 1px solid lightgray;
  border-radius: 50px;
  text-align: center;
  height: 40px;
  width: 300px;
  ${mobile({ width: "50px" })}
`;
//left

//center
export const Center = styled.div`
  flex: 1;
  text-align: center;
`;
export const IL = styled(LinkR)`
  color: inherit;
`;
export const Image = styled.img`
  width: 260px;
  height: 160px;
  display: block;
  padding-left: 10px;
  margin-left: auto;
  margin-right: auto;
`;
export const Logo = styled.h1`
  font-weight: bold;
  ${mobile({ fontSize: "24px" })}
`;
//center

//right
export const Right = styled.div`
  flex: 1;
  display: flex;
  align-items: center;
  justify-content: flex-end;
  ${mobile({ flex: 2, justifyContent: "center" })}
`;

export const MenuItem = styled.div`
  font-size: 14px;
  cursor: pointer;
  margin-left: 25px;
  ${mobile({
    fontSize: "12px",
    marginLeft: "10px",
  })};
`;
export const MIL = styled(LinkR)`
  text-decoration: none;
  color: #e19db9;
  font-size: 14px;
  font-weight: bold;
  text-transform: lowercase;
  text-shadow: 0px 0px 4px #e19db9;
  &:hover {
    text-transform: uppercase;
  }
  ${mobile({ display: "none" })}
`;
export const MILC = styled(LinkR)`
  text-decoration: none;
  color: #e19db9;
`;
//right
