import { Badge } from "@material-ui/core";
import {
  Search,
  ShoppingCartOutlined,
  ExitToAppOutlined,
  Menu,
} from "@material-ui/icons";
import React, { useEffect, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import { useHistory } from "react-router-dom";
import { logoutUser } from "../../redux/api/authApiCalls";
import {
  NavContainer,
  NavWrapper,
  Left,
  MobileIcon,
  Center,
  Image,
  Right,
  MenuItem,
  IL,
  MIL,
  MILC,
} from "./NavbarElements";
import logo from "../../assets/image1.png";
import Sidebar from "../sidebar/SideBar";

const Navbar = () => {
  const history = useHistory();
  const dispatch = useDispatch();
  const { products } = useSelector((state) => state.cart);
  const isAuth = useSelector((state) => state.auth?.isAuthenticated);
  const user = useSelector((state) => state.auth?.currentUser);
  const [isOpen, setIsOpen] = useState(false);

  const toggle = () => {
    setIsOpen(!isOpen);
  };

  const getTotalQuantity = () => {
    let total = 0;
    
    products.map((item) => {
      total += item.quantity;
    });
    return total;
  };

  const handleLogout = (e) => {
    e.preventDefault();
    dispatch(logoutUser);
    localStorage.removeItem("persist.root");
    history.push("/login");
  };

  const userAuthenticated = !isAuth ? (
    <>
      <Sidebar isOpen={isOpen} toggle={toggle} />
      <NavContainer>
        <NavWrapper>
          <Left>
            <MobileIcon onClick={toggle}>
              <Menu />
            </MobileIcon>
            <MenuItem>
              <MIL to="/register">Register</MIL>
            </MenuItem>
          </Left>
          <Center>
            <IL to="/">
              <Image src={logo} />
            </IL>
          </Center>
          <Right>
            <MenuItem>
              <MIL to="/login">Login</MIL>
            </MenuItem>
          </Right>
        </NavWrapper>
      </NavContainer>
    </>
  ) : (
    <>
      <Sidebar isOpen={isOpen} toggle={toggle} />
      <NavContainer>
        <NavWrapper>
          <Left>
            <MobileIcon onClick={toggle}>
              <Menu />
            </MobileIcon>
          </Left>
          <Center>
            <IL to="/">
              <Image src={logo} />
            </IL>
          </Center>
          <Right>
            <MenuItem>
              Olá,{" "}
              <IL to={`/profile/${user._id}`}>
                <b>{user.username}</b>
              </IL>
            </MenuItem>
            <MenuItem onClick={handleLogout}>
              <ExitToAppOutlined />
            </MenuItem>
            <MenuItem mitName="cart">
              <MILC to="/cart">
                {" "}
                <Badge
                  badgeContent={getTotalQuantity() || 0}
                  color="error"
                  overlap="rectangular"
                >
                  <ShoppingCartOutlined />
                </Badge>
              </MILC>
            </MenuItem>
          </Right>
        </NavWrapper>
      </NavContainer>
    </>
  );
  return userAuthenticated;
};

export default Navbar;
