export const sliderItems = [
  {
    id: 1,
    img: require("./assets/bg007.jpg"),
    title: (
      <div style={{ color: "#FFF" }}>
        <span style={{ color: "#ed0101" }}>ELEGANTE </span>
        DA
        <br />
        CABEÇA AOS PÉS
      </div>
    ),
    desc: (
      <>
        <span style={{ color: "#FFF" }}>
          Todas as coisas certas,
          <br />
          para todas as pessoas certas.
        </span>
      </>
    ),
    color: "#fff",
    bg: "#f5fafd",
    top: "45%",
    right: "15%",
  },
  {
    id: 2,
    img: "https://images.unsplash.com/photo-1528000936410-deb9b2dee794?q=80&w=1032&auto=format&fit=crop&ixlib=rb-4.0.3&ixid=M3wxMjA3fDB8MHxwaG90by1wYWdlfHx8fGVufDB8fHx8fA%3D%3D",
    title: (
      <div style={{ color: "#FFF" }}>
        DESCUBRA A
        <br />
        <span style={{ color: "#ed0101" }}>DEUSA</span>
        <br />
        EM VOCÊ.
      </div>
    ),
    desc: (
      <>
        <span style={{ color: "#FFF" }}>
          Todas as melhores coisas,
          <br />
          para todas as melhores pessoas.
        </span>
      </>
    ),
    color: "#fff",
    bg: "#302f2e",
    top: "10%",
    left: "10%",
  },
  {
    id: 3,
    img: "https://images.unsplash.com/photo-1526381999948-730fa670e7d2?q=80&w=973&auto=format&fit=crop&ixlib=rb-4.0.3&ixid=M3wxMjA3fDB8MHxwaG90by1wYWdlfHx8fGVufDB8fHx8fA%3D%3D",
    title: (
      <>
        <p style={{ color: "#FFF" }}>
          ENCONTRE SUA
          <br />
          <span style={{ color: "#ed0101" }}> DIVA </span>
          INTERIOR
          <br />
        </p>
      </>
    ),
    desc: (
      <>
        <span style={{ color: "#FFF" }}>"Atreva-se!"</span>
      </>
    ),
    color: "#fff",
    bg: "#fbf0f4",
    top: "50%",
    right: "25%",
  },
];

export const categories = [
  {
    id: 1,
    img: "https://images.pexels.com/photos/1087727/pexels-photo-1087727.jpeg?auto=compress&cs=tinysrgb&w=1260&h=750&dpr=1",
    title: "BAGS",
    category: "bags",
  },
  {
    id: 2,
    img: "https://images.pexels.com/photos/1616096/pexels-photo-1616096.jpeg?auto=compress&cs=tinysrgb&w=1260&h=750&dpr=1",
    title: "Bijuterias",
    category: "bijuterias",
  },
];

export const popularProducts = [
  {
    id: 1,
    img: "https://d3o2e4jr3mxnm3.cloudfront.net/Mens-Jake-Guitar-Vintage-Crusher-Tee_68382_1_lg.png",
  },
  {
    id: 2,
    img: "https://cdn.shopify.com/s/files/1/0101/4832/products/Angela_Natural_Tee.png?v=1606780388",
  },
  {
    id: 3,
    img: "https://www.prada.com/content/dam/pradanux_products/U/UCS/UCS319/1YOTF010O/UCS319_1YOT_F010O_S_182_SLF.png",
  },
  {
    id: 4,
    img: "https://www.burdastyle.com/pub/media/catalog/product/cache/7bd3727382ce0a860b68816435d76e26/107/BUS-PAT-BURTE-1320516/1170x1470_BS_2016_05_132_front.png",
  },
  {
    id: 5,
    img: "https://images.ctfassets.net/5gvckmvm9289/3BlDoZxSSjqAvv1jBJP7TH/65f9a95484117730ace42abf64e89572/Noissue-x-Creatsy-Tote-Bag-Mockup-Bundle-_4_-2.png",
  },
  {
    id: 6,
    img: "https://d3o2e4jr3mxnm3.cloudfront.net/Rocket-Vintage-Chill-Cap_66374_1_lg.png",
  },
  {
    id: 7,
    img: "https://www.pngarts.com/files/4/Fashion-Accessory-PNG-Free-Download.png",
  },
  {
    id: 8,
    img: "https://www.pngarts.com/files/3/Women-Jacket-PNG-High-Quality-Image.png",
  },
];
