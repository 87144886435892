import { useState } from "react";
import { login } from "../../redux/api/authApiCalls";
import { useDispatch, useSelector } from "react-redux";
import {
  LoginContainer,
  Wrapper,
  Title,
  Form,
  FormItem,
  Label,
  Input,
  Button,
  Link,
  IL,
} from "./LoginElements";

const Login = () => {
  const dispatch = useDispatch();
  const [username, setUsername] = useState("");
  const [password, setPassword] = useState("");
  const { isFetching, loginError } = useSelector((state) => state.auth);

  const handleClick = (e) => {
    e.preventDefault();
    login(dispatch, { username, password });
  };
  return (
    <LoginContainer>
      <Wrapper>
        {loginError && (
          <p
            className={loginError ? "errmsg" : "offscreen"}
            aria-live="assertive"
          >
            {loginError?.data?.message || loginError?.data}
          </p>
        )}

        <Title>SIGN IN</Title>
        <Form>
          <FormItem>
            <Label htmlFor="username">Utilizador:</Label>
            <Input
              placeholder="Insira o nome do utilizador"
              id="username"
              type="text"
              onChange={(e) => setUsername(e.target.value)}
            />
          </FormItem>
          <FormItem>
            <Label htmlFor="password">Senha:</Label>
            <Input
              placeholder="Insira a senha"
              id="password"
              type="password"
              onChange={(e) => setPassword(e.target.value)}
            />
          </FormItem>
          <Button onClick={handleClick} disabled={isFetching}>
            LOGIN
          </Button>
          <Link>VOCÊ NÃO SE LEMBRA DA SENHA?</Link>
          <IL to="/register">CRIAR UMA NOVA CONTA</IL>
        </Form>
      </Wrapper>
    </LoginContainer>
  );
};

export default Login;
