import {
  RegisterContainer,
  Wrapper,
  Title,
  Form,
  FormItem,
  Label,
  Input,
  Agreement,
  Button,
  IL,
} from "./RegisterElements";

import { useState, useEffect, useRef } from "react";
import { useDispatch, useSelector } from "react-redux";
import { register } from "../../redux/api/authApiCalls";
import { CheckCircle, Close, Info } from "@material-ui/icons";

const USERNAME_REGEX = /^[A-z][A-z0-9-_]{3,23}$/;
const EMAIL_REGEX = /\b[\w\.-]+@[\w\.-]+\.\w{2,4}\b/;
const PWD_REGEX = /^(?=.*[a-z])(?=.*[A-Z])(?=.*[0-9])(?=.*[!@#$%]).{8,24}$/;

const Register = () => {
  const userRef = useRef();
  const dispatch = useDispatch();

  const [username, setUsername] = useState("");
  const [validName, setValidName] = useState(false);
  const [userNameFocus, setUserNameFocus] = useState(false);

  const [email, setEmail] = useState("");
  const [validEmail, setValidEmail] = useState(false);
  const [emailFocus, setEmailFocus] = useState(false);

  const [password, setPassword] = useState("");
  const [validPwd, setValidPwd] = useState(false);
  const [pwdFocus, setPwdFocus] = useState(false);

  const [matchPwd, setMatchPwd] = useState("");
  const [validMatch, setValidMatch] = useState(false);
  const [matchFocus, setMatchFocus] = useState(false);

  const { isFetching, registerError } = useSelector((state) => state.auth);
  const [errMsg, setErrMsg] = useState("");

  useEffect(() => {
    userRef.current.focus();
  }, []);

  useEffect(() => {
    setValidName(USERNAME_REGEX.test(username));
  }, [username]);

  useEffect(() => {
    setValidEmail(EMAIL_REGEX.test(email));
  }, [email]);

  useEffect(() => {
    setValidPwd(PWD_REGEX.test(password));
    setValidMatch(password === matchPwd);
  }, [password, matchPwd]);

  useEffect(() => {
    setErrMsg("");
  }, [username, email, password, matchPwd]);

  const handleSubmit = (e) => {
    e.preventDefault();
    const v1 = USERNAME_REGEX.test(username);
    const v2 = EMAIL_REGEX.test(email);
    const v3 = PWD_REGEX.test(password);
    if (!v1 || !v2 || !v3) {
      setErrMsg("Invalid");
      return;
    }
    register(dispatch, { username, email, password });
  };

  return (
    <RegisterContainer>
      <Wrapper>
        {registerError && (
          <p
            className={registerError ? "errmsg" : "offscreen"}
            aria-live="assertive"
          >
            {registerError?.data?.error}
          </p>
        )}
        <Title>Register</Title>
        <Form onSubmit={handleSubmit}>
          <FormItem>
            <Label htmlFor="username">
              Utilizador:
              <span className={validName ? "valid" : "hide"}>
                <CheckCircle />
              </span>
              <span className={validName || !username ? "hide" : "invalid"}>
                <Close />
              </span>
            </Label>
            <Input
              placeholder="Insira o nome do utilizador"
              type="text"
              id="username"
              autoComplete="off"
              ref={userRef}
              value={username}
              required
              onChange={(e) => setUsername(e.target.value)}
              aria-invalid={validName ? "false" : "true"}
              aria-describedby="uidnote"
              onFocus={() => setUserNameFocus(true)}
              onBlur={() => setUserNameFocus(false)}
            />
            <p
              id="uidnote"
              className={
                userNameFocus && username && !validName
                  ? "instructions"
                  : "offscreen"
              }
            >
              <Info />
              4 to 24 characters.
              <br />
              Must begin with a letter.
              <br />
              Letters, numbers, underscores, hyphens allowed.
            </p>
          </FormItem>
          <FormItem>
            {" "}
            <Label htmlFor="email">
              Email:{" "}
              <span className={validEmail ? "valid" : "hide"}>
                <CheckCircle />
              </span>
              <span className={validEmail || !email ? "hide" : "invalid"}>
                <Close />
              </span>
            </Label>
            <Input
              placeholder="Insira o email"
              id="email"
              type="email"
              autoComplete="off"
              value={email}
              required
              onChange={(e) => setEmail(e.target.value)}
              aria-invalid={validEmail ? "false" : "true"}
              aria-describedby="emailnote"
              onFocus={() => setEmailFocus(true)}
              onBlur={() => setEmailFocus(false)}
            />
            <p
              id="emailnote"
              className={
                emailFocus && email && !validEmail
                  ? "instructions"
                  : "offscreen"
              }
            >
              Must be a valid email
              <br />
            </p>
          </FormItem>

          <FormItem>
            {" "}
            <Label htmlFor="password">
              Senha:{" "}
              <span className={validPwd ? "valid" : "hide"}>
                <CheckCircle />
              </span>
              <span className={validPwd || !password ? "hide" : "invalid"}>
                <Close />
              </span>
            </Label>
            <Input
              placeholder="Insira a senha"
              type="password"
              id="password"
              value={password}
              required
              onChange={(e) => setPassword(e.target.value)}
              aria-invalid={validPwd ? "false" : "true"}
              aria-describedby="pwdnote"
              onFocus={() => setPwdFocus(true)}
              onBlur={() => setPwdFocus(false)}
            />
            <p
              id="pwdnote"
              className={pwdFocus && !validPwd ? "instructions" : "offscreen"}
            >
              8 to 24 characters.
              <br />
              Must include uppercase and lowercase letters, a number and a
              special character.
              <br />
              Allowed special characters:{" "}
              <span aria-label="exclamation mark">!</span>{" "}
              <span aria-label="at symbol">@</span>{" "}
              <span aria-label="hashtag">#</span>{" "}
              <span aria-label="dollar sign">$</span>{" "}
              <span aria-label="percent">%</span>
            </p>
          </FormItem>

          <FormItem>
            <Label htmlFor="confirmPassword">
              Confirme a senha:{" "}
              <span className={validMatch && matchPwd ? "valid" : "hide"}>
                <CheckCircle />
              </span>
              <span className={validMatch || !matchPwd ? "hide" : "invalid"}>
                <Close />
              </span>
            </Label>
            <Input
              placeholder="Re-insira a senha"
              type="password"
              id="confirmPassword"
              onChange={(e) => setMatchPwd(e.target.value)}
              value={matchPwd}
              required
              aria-invalid={validMatch ? "false" : "true"}
              aria-describedby="confirmnote"
              onFocus={() => setMatchFocus(true)}
              onBlur={() => setMatchFocus(false)}
            />
            <p
              id="confirmnote"
              className={
                matchFocus && !validMatch ? "instructions" : "offscreen"
              }
            >
              {/* <FontAwesomeIcon icon={faInfoCircle} /> */}A senha deve
              corresponder ao primeiro campo de entrada de senha.
            </p>
          </FormItem>

          <Agreement>
            Ao criar uma conta, concordo com o processamento dos meus dados
            pessoais de acordo com a <br /> <b>POLÍTICA DE PRIVACIDADE</b>
          </Agreement>
          <Button
            disabled={
              !validName ||
              !validEmail ||
              !validPwd ||
              !validMatch ||
              isFetching
                ? true
                : false
            }
          >
            CRIAR CONTA
          </Button>
          <IL to="/login">Já possui uma conta?</IL>
        </Form>
      </Wrapper>
    </RegisterContainer>
  );
};

export default Register;
