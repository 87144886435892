import styled from "styled-components";
import { Link as LinkR } from "react-router-dom";
import { mobile } from "../../responsive";

export const Container = styled.div`
  display: flex;
  height: 80vh;
  align-items: center;
  background-color: white;
  justify-content: center;
`;

export const Wrapper = styled.div`
  display: flex;
  flex-direction: column;
  width: 50%;
  padding: 20px;
  background-color: white;
  align-items: center;
  justify-content: center;
  border-radius: 50px;
  box-shadow: 2px 4px 10px 1px rgb(201 201 201 / 47%);
  ${mobile({ width: "75%" })};
`;

export const Title = styled.h1`
  color: #9abc66;
`;
export const Svg = styled.svg`
  border-radius: 200px;
  height: 200px;
  width: 200px;
  background: ${(props) => (props.type === "success" ? "#F8FAF5" : "#f5d4d4")};
  margin: 0 auto;
`;

export const Button = styled.button`
  width: 100%;
  border: none;
  border-radius: 50px;
  padding: 15px 20px;
  background: ${(props) => (props.type === "success" ? "#F8FAF5" : "#f5d4d4")};
  color: ${(props) => (props.type === "success" ? "#F8FAF5" : "#f5d4d4")};
  cursor: pointer;
  margin: 20px 0px;
`;
export const IL = styled(LinkR)`
  color: ${(props) => (props.type === "success" ?  "#9abc66" : "red")};
`;
