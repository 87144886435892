import styled from "styled-components";
import { mobile } from "../../responsive";

export const Wrapper = styled.div`
  overflow: hidden;
`;

export const Container = styled.div`
  max-width: 1200px;
  background-color: white;
  overflow: hidden;
  margin: auto;
  margin-top: 1rem;
  margin-bottom: 3rem;
  padding: 0 2rem;
  display: flex;
  flex-direction: column;
  ${mobile({
    padding: "0px",
  })}
`;

export const TitleContainer = styled.div`
  display: flex;
  justify-content: space-between;
  align-items: center;
  color: teal;
  ${mobile({
    fontSize: "15px",
  })}
`;

export const TitleDiv = styled.div`
  display: flex;
  align-items: center;
  margin-left: 16px;
`;

export const Title = styled.h1``;

export const Icon = styled.span`
  margin-right: 10px;
  font-size: 24px;
`;

export const AdminDashboard = styled.button`
  background-color: teal;
  color: white;
  padding: 8px;
  cursor: pointer;
  border: none;
  border-radius: 5px;
  font-size: 16px;
  font-weight: 500;
`;

export const ProfileTop = styled.div`
  display: flex;
  gap: 10px;
  width: 100%;
  padding: 16px;
  cursor: pointer;
  ${mobile({
    flexDirection: "column",
  })}
`;

export const TopLeftContainer = styled.div`
  display: flex;
  flex-direction: column;
  width: 50%;
  ${mobile({
    width: "100%",
  })}
`;
export const TopRightContainer = styled.div`
  display: flex;
  flex-direction: column;
  width: 50%;
  ${mobile({
    width: "100%",
  })}
`;

export const BtnGroup = styled.div`
  display: flex;
  justify-content: space-between;
  margin: 10px 0px 20px 0;
  ${mobile({
    marginRight: "20px",
  })}
`;

export const CancelBtn = styled.button`
  width: 70px;
  padding: 8px;
  border: none;
  background-color: crimson;
  color: white;
  cursor: pointer;
  font-size: 16px;
  border-radius: 5px;
  transition: all 0.3s ease;
  &:hover {
    transform: scale(1.07);
  }
`;

export const UpdateBtn = styled.button`
  width: 70px;
  padding: 8px;
  border: none;
  background-color: teal;
  color: white;
  cursor: pointer;
  font-size: 16px;
  border-radius: 5px;
  transition: all 0.3s ease;
  &:hover {
    transform: scale(1.07);
  }
`;

export const TopLeft = styled.div`
  -webkit-box-shadow: 0px 0px 16px -8px rgba(0, 0, 0, 0.68);
  box-shadow: 0px 0px 16px -8px rgba(0, 0, 0, 0.68);
  background-color: white;
  padding: 10px;
  position: relative;
  height: fit-content;
`;

export const TopLeftTitle = styled.h2`
  margin-bottom: 10px;
  color: #08173b;
`;

export const TopRight = styled.div`
  -webkit-box-shadow: 0px 0px 16px -8px rgba(0, 0, 0, 0.68);
  box-shadow: 0px 0px 16px -8px rgba(0, 0, 0, 0.68);
  background-color: white;
  padding: 10px;
  position: relative;
  height: fit-content;
  ${mobile({
    width: "100%",
  })}
`;

export const EditBtn = styled.span`
  position: absolute;
  top: 16px;
  right: 16px;
  color: teal;
`;

export const InfoInput = styled.input`
  border: none;
  border-bottom: 1px solid #08173b;
  width: 100%;
  font-size: 16px;
  padding: 5px;
  &:focus {
    outline: none;
    border-bottom: 2px solid #08173b;
  }
`;

export const InfoContainer = styled.div`
  display: flex;
  align-items: center;
  margin: 16px 0;
`;

export const InfoTitle = styled.h3`
  margin-right: 10px;
  color: #08173b;
`;

export const InfoDesc = styled.p``;

export const InfoSelect = styled.select`
  cursor: pointer;
  padding: 3px;
`;

export const InfoOption = styled.option``;

export const ProfileBottom = styled.div`
  -webkit-box-shadow: 0px 0px 16px -8px rgba(0, 0, 0, 0.68);
  box-shadow: 0px 0px 16px -8px rgba(0, 0, 0, 0.68);
  background-color: white;
  padding: 10px;
  height: fit-content;
  width: 100%;
  overflow-x: auto;
`;

export const TableDiv = styled.div`
  width: 100%;
  height: 50vh;
`;

export const Table = styled.table`
  border-collapse: collapse;
  border-spacing: 0;
  width: 100%;
  border: 1px solid #ddd;
`;

export const TableHead = styled.thead``;
export const TableBody = styled.tbody``;

export const TableRow = styled.tr`
  &:nth-child(even) {
    background-color: #f2f2f2;
  }
  &:hover {
    background-color: orange;
  }
`;

export const TableHeading = styled.th`
  text-align: left;
  padding: 8px;
`;

export const TableData = styled.td`
  text-align: left;
  padding: 8px;
`;

export const Button = styled.button`
  padding: 5px 8px;
  cursor: pointer;
  border: none;
  background-color: #08173b;
  color: white;
  border-radius: 3px;
  letter-spacing: 1px;
`;
