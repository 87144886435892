import { Send } from '@material-ui/icons'
import React from 'react'
import { NLContainer ,Title,Description,InputContainer,Input,Button } from './NewsletterElements'

const Newsletter = () => {
  return (
    <NLContainer>
      <Title>Newsletter</Title>
      <Description>Get timely updates from your favorite products.</Description>
      <InputContainer>
        <Input placeholder="Your email" />
        <Button>
          <Send />
        </Button>
      </InputContainer>
    </NLContainer>
  );
}

export default Newsletter