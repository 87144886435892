import React from "react";
import { Link } from "react-router-dom";
import { CIContainer, Image, Info, Title, Button } from "./CategoriesElements";

const CategoryItem = ({ item }) => {
  return (
    <CIContainer>
      <Link to={`/products/${item.category}`}>
        <Image src={item.img} />
        <Info>
          <Title>{item.title}</Title>
          <Button>Ver Artigos</Button>
        </Info>
      </Link>
    </CIContainer>
  );
};

export default CategoryItem;
