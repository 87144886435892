import React, { useEffect, useState } from "react";
import {
  CartContainer,
  Wrapper,
  Title,
  Top,
  TopButton,
  TopTexts,
  TopText,
  Bottom,
  Info,
  Product,
  ProductDetail,
  Image,
  Details,
  ProductName,
  ProductId,
  ProductColor,
  PriceDetail,
  ProductAmountContainer,
  ProductPrice,
  ProductAmount,
  Hr,
  Summary,
  SummaryTitle,
  SummaryItem,
  SummaryItemText,
  SummaryItemPrice,
  Button,
} from "./CartElements";
import { Add, Remove } from "@material-ui/icons";
import { useDispatch, useSelector } from "react-redux";
import StripeCheckout from "react-stripe-checkout";
import { Link, useHistory } from "react-router-dom";
import { userRequest } from "../../requestMethods";
import {
  clearCart,
  decrementQuantity,
  deleteProductFromCart,
  incrementQuantity,
} from "../../redux/reducers/cartReducer";
import img from "../../assets/logosm.png";
const KEY = process.env.REACT_APP_STRIPE_KEY;

const Cart = () => {
  const history = useHistory();
  const dispatch = useDispatch();
  const { products } = useSelector((state) => state.cart);
  const [stripeToken, setStripeToken] = useState(null);

  const getTotalQuantity = () => {
    return products.reduce((total, item) => {
      return total + item.quantity;
    }, 0);
  };

  const getTotalPrice = () => {
    return products.reduce((total, item) => {
      return (total += item.quantity * item.price);
    }, 0);
  };

  const totalPrice = getTotalPrice();
  const shippingPriceDiscount = totalPrice >= 50 ? 0 : 5.9;
  const totalPriceWithDiscount = totalPrice + shippingPriceDiscount;

  const onToken = (token) => {
    setStripeToken(token);
  };

  const handleClearCart = () => {
    dispatch(clearCart());
  };

  useEffect(() => {
    const makeRequest = async () => {
      try {
        const res = await userRequest.post("/checkout/payment", {
          tokenId: stripeToken.id,
          amount: totalPrice * 100,
        });
        history.push("/success", {
          stripeData: res.data,
          products: products,
          total: totalPrice,
        });
      } catch {}
    };
    stripeToken && makeRequest();
  }, [stripeToken, products, totalPrice, history]);

  return (
    <>
      <CartContainer>
        <Wrapper>
          <Title>YOUR BAG</Title>
          <Top>
            <TopButton>
              <Link to="/" style={{ color: "inherit", textDecoration: "none" }}>
                CONTINUE SHOPPING
              </Link>
            </TopButton>
            <TopTexts>
              <TopText>Shopping Bag({getTotalQuantity()})</TopText>
            </TopTexts>
            <TopButton type="filled" onClick={handleClearCart}>
              ClearCart
            </TopButton>
          </Top>
          <Bottom>
            <Info>
              {products?.map((product) => (
                <Product product={product} key={product._id}>
                  <ProductDetail>
                    <Image src={product.image} />
                  </ProductDetail>
                  <ProductDetail>
                    <Details>
                      <ProductName>
                        <b>Product:</b> {product.title}
                      </ProductName>
                      <ProductId>
                        <b>ID:</b> {product._id}
                      </ProductId>
                      <ProductColor>
                        <b>Descrição:</b>
                        {product.description}
                      </ProductColor>
                    </Details>
                  </ProductDetail>
                  <PriceDetail>
                    <ProductAmountContainer>
                      <Add
                        onClick={() => {
                          dispatch(incrementQuantity(product._id));
                        }}
                      />
                      <ProductAmount>{product.quantity}</ProductAmount>
                      <Remove
                        onClick={() => {
                          dispatch(decrementQuantity(product._id));
                        }}
                      />
                      <Button
                        onClick={() => {
                          dispatch(deleteProductFromCart(product._id));
                        }}
                      >
                        Remover
                      </Button>
                    </ProductAmountContainer>
                    <ProductPrice>
                      € {product.price * product.quantity}
                    </ProductPrice>
                  </PriceDetail>
                </Product>
              ))}
              <Hr />
            </Info>
            <Summary>
              <SummaryTitle>ORDER SUMMARY</SummaryTitle>
              <SummaryItem>
                <SummaryItemText>Subtotal</SummaryItemText>
                <SummaryItemPrice>{totalPrice}€</SummaryItemPrice>
              </SummaryItem>
              <SummaryItem>
                <SummaryItemText>Estimated Shipping</SummaryItemText>
                <SummaryItemPrice>{shippingPriceDiscount}€</SummaryItemPrice>
              </SummaryItem>

              {totalPrice !== 0 ? (
                <SummaryItem type="total">
                  <SummaryItemText>Total</SummaryItemText>
                  <SummaryItemPrice>{totalPriceWithDiscount}€</SummaryItemPrice>
                </SummaryItem>
              ) : (
                <></>
              )}
              <StripeCheckout
                name="d'Kriolu"
                image={img}
                billingAddress
                shippingAddress
                description={`Your total is €${totalPriceWithDiscount}`}
                amount={totalPriceWithDiscount * 100}
                token={onToken}
                stripeKey={KEY}
              >
                <Button>CHECKOUT NOW</Button>
              </StripeCheckout>
            </Summary>
          </Bottom>
        </Wrapper>
      </CartContainer>
    </>
  );
};

export default Cart;
