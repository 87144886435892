import { Search } from "@material-ui/icons";
import React, { useState } from "react";
import { useLocation } from "react-router-dom";

import Products from "../../components/products/Products";
import {
  PLContainer,
  Title,
  FilterContainer,
  Filter,
  FilterText,
  Select,
  Option,
  SearchContainer,
  Input,
} from "./ProductElements";

const ProductList = () => {
  const location = useLocation();
  const category = location.pathname.split("/")[2];
  const [filters, setFilters] = useState({});
  const [searchQuery, setSearchQuerry] = useState("");
  const [sort, setSort] = useState("newest");

  const handleFilters = (e) => {
    const value = e.target.value;
    setFilters({
      ...filters,
      [e.target.name]: value,
    });
  };
  const handleSearch = (e) => {
    setSearchQuerry(e.target.value.toLowerCase());
  };
  return (
    <PLContainer>
      <Title>{category ? category.toUpperCase() : ""}</Title>
      {category ? (
        category === "bags" ? (
          <FilterContainer>
            <Filter>
              <FilterText>Filter Products:</FilterText>
              <Select name="subCategory" onChange={handleFilters}>
                <Option disabled>Sub-Categoria</Option>
                <Option value="bolsasMultiusos">Bolsas Multiusos</Option>
                <Option value="bolsasParaTelemovel">
                  Bolsas Para Telemovel
                </Option>
                <Option value="bolsasTote">Bolsas Tote</Option>
                <Option value="carteiras">Carteiras</Option>
                <Option value="clutches">Clutches</Option>
                <Option value="malasDeFesta">Malas De Festa</Option>
                <Option value="malasDeTiracolo">Malas De Tiracolo</Option>
                <Option value="malasPersonalizaveis">
                  Malas Personalizaveis
                </Option>
                <Option value="malasDeMao">Malas De Mao </Option>
                <Option value="malasDeOmbro">Malas De Ombro</Option>
                <Option value="malasGrandes">Malas Grandes</Option>
                <Option value="malasPequenas">Malas Pequenas </Option>
                <Option value="mochilas">Mochilas</Option>
                <Option value="pastas">Pastas</Option>
                <Option value="pochetes">Pochetes</Option>
                <Option value="lancheiras">Lancheiras</Option>
                <Option value="portaMoedas">Porta Moedas</Option>
              </Select>
            </Filter>
            <Filter>
              <FilterText>Sort Products:</FilterText>
              <Select onChange={(e) => setSort(e.target.value)}>
                <Option value="newest">Newest</Option>
                <Option value="asc">Price (asc)</Option>
                <Option value="desc">Price (desc)</Option>
              </Select>
            </Filter>
          </FilterContainer>
        ) : (
          <>
            {" "}
            <FilterContainer>
              <Filter>
                <FilterText>Filter Products:</FilterText>
                <Select name="subCategory" onChange={handleFilters}>
                  <option value="joiaDeCorpo">Joia De Corpo</option>
                </Select>
              </Filter>
              <Filter>
                <FilterText>Sort Products:</FilterText>
                <Select onChange={(e) => setSort(e.target.value)}>
                  <Option value="newest">Newest</Option>
                  <Option value="asc">Price (asc)</Option>
                  <Option value="desc">Price (desc)</Option>
                </Select>
              </Filter>
            </FilterContainer>
          </>
        )
      ) : (
        <SearchContainer>
          <Input placeholder="Search" onChange={handleSearch} />
          <Search style={{ color: "gray", fontSize: "16px" }} />
        </SearchContainer>
      )}
      <Products
        category={category}
        filters={filters}
        sort={sort}
        searchQuery={searchQuery}
      />
    </PLContainer>
  );
};

export default ProductList;
