import styled from "styled-components";
import { Link as LinkR } from "react-router-dom";
import { mobile } from "../../responsive";
export const RegisterContainer = styled.div`
  width: 100vw;
  height: 100vh;
  background: linear-gradient(
      rgba(255, 255, 255, 0.5),
      rgba(255, 255, 255, 0.5)
    ),
    url("https://images.unsplash.com/photo-1583316174775-bd6dc0e9f298?q=80&w=2070&auto=format&fit=crop&ixlib=rb-4.0.3&ixid=M3wxMjA3fDB8MHxwaG90by1wYWdlfHx8fGVufDB8fHx8fA%3D%3D")
      center;
  background-size: cover;
  display: flex;
  align-items: center;
  justify-content: center;
  margin-top: 10px;
`;

export const Wrapper = styled.div`
  width: 50%;
  padding: 20px;
  background-color: white;
  ${mobile({ width: "75%" })}
`;

export const Title = styled.h1`
  font-size: 24px;
  font-weight: 300;
  text-align: center;
  color: #e14a89;
`;

export const Form = styled.form`
  display: flex;
  flex-direction: column;
  justify-content: space-evenly;
  flex-grow: 1;
  padding-bottom: 1rem;
  //flex-wrap: wrap;
`;
export const FormItem = styled.div`
  min-width: 100%;
`;
export const Label = styled.label`
  margin-top: 1rem;
`;

export const Input = styled.input`
  flex: 1;
  min-width: 100%;
  margin-bottom: 10px;
  padding: 0.25rem;
`;

export const Agreement = styled.span`
  font-size: 12px;
  margin: 20px 0px;
  text-align: center;
  flex: 1 1 auto;
`;

export const Button = styled.button`
  width: 100%;
  border: none;
  padding: 15px 20px;
  background-color: #e19db9;
  margin-bottom: 10px;
  color: white;
  cursor: pointer;
  &:disabled {
    border: 1px solid #e19db9;
    background-color: #fff;
    color: #e19db9;
    cursor: not-allowed;
  }
`;

export const IL = styled(LinkR)`
  color: inherit;
`;
