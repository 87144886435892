import React from "react";
import {
  Facebook,
  Instagram,
  MailOutline,
  Phone,
  Room,
  Assignment,
  TrackChanges
} from "@material-ui/icons";
import {
  Container,
  Left,
  Image,
  Description,
  SocialContainer,
  SocialIcon,
  Center,
  Title,
  List,
  ListItem,
  Right,
  ContactItem,
  Payment,
} from "./FooterElements";
import logo from "../../assets/image1.png";
const Footer = () => {
  return (
    <Container>
      <Left>
        <Image src={logo} />
        <Description>
          "Sua personalidade no seu vestuário." <br />
        </Description>
        <SocialContainer>
          <SocialIcon color="3B5999">
            <Facebook />
          </SocialIcon>
          <SocialIcon color="E4405F">
            <Instagram />
          </SocialIcon>
        </SocialContainer>
      </Left>
      <Center>
        <Title>Useful Links</Title>
        <ContactItem>
          <TrackChanges style={{ marginRight: "10px" }} /> Order Tracking
        </ContactItem>
        <ContactItem>
          <Assignment style={{ marginRight: "10px" }} /> Terms
        </ContactItem>
      </Center>
      <Right>
        <Title>Contact</Title>
        {/* <ContactItem>
          <Room style={{ marginRight: "10px" }} /> ADD
        </ContactItem>
        <ContactItem>
          <Phone style={{ marginRight: "10px" }} /> +351 234 56 78
        </ContactItem>
      */}
        <ContactItem>
          <MailOutline style={{ marginRight: "10px" }} /> afrotropik@gmail.com
        </ContactItem>
        <Payment src="https://i.ibb.co/Qfvn4z6/payment.png" />
      </Right>
    </Container>
  );
};

export default Footer;
