import { Add, Remove } from "@material-ui/icons";
import React, { useEffect, useState } from "react";
import { Link,  useLocation } from "react-router-dom";

import { publicRequest } from "../../requestMethods";
import { useDispatch, useSelector } from "react-redux";
import {
  PContainer,
  Wrapper,
  ImgContainer,
  Image,
  InfoContainer,
  TitleP,
  Description,
  Price,
  AddContainer,
  AmountContainer,
  Amount,
  Button,
} from "./ProductElements";
import { addProductToCart } from "../../redux/reducers/cartReducer";

const Product = () => {
  const dispatch = useDispatch();
  const location = useLocation();
  const id = location.pathname.split("/")[2];

  const [product, setProduct] = useState({});
  const [quantity, setQuantity] = useState(1);

  const isAuthenticated = useSelector((state) => state.auth.isAuthenticated);

  useEffect(() => {
    const getProduct = async () => {
      try {
        const res = await publicRequest.get("/products/find/" + id);
        setProduct(res.data);
      } catch {}
    };
    getProduct();
  }, [id]);

  const handleQuantity = (type) => {
    if (type === "dec") {
      quantity > 1 && setQuantity(quantity - 1);
    } else {
      setQuantity(quantity + 1);
    }
  };

  const handleClick = () => {
    dispatch(addProductToCart({ ...product, quantity }));
  };

  return (
    <PContainer>
      <Wrapper>
        <ImgContainer>
          <Image src={product.image} />
        </ImgContainer>
        <InfoContainer>
          <TitleP>{product.title}</TitleP>
          <Description>{product.description}</Description>
          <Price>{product.price} €</Price>
          <AddContainer>
            <AmountContainer>
              <Remove onClick={() => handleQuantity("dec")} />
              <Amount>{quantity}</Amount>
              <Add onClick={() => handleQuantity("inc")} />
            </AmountContainer>
            {!isAuthenticated ? (
              <Link to="/login">
                <Button>ADD TO CART</Button>
              </Link>
            ) : (
              <Button onClick={handleClick}>ADD TO CART</Button>
            )}
          </AddContainer>
        </InfoContainer>
      </Wrapper>
    </PContainer>
  );
};

export default Product;
