import {
  AccountBoxOutlined,
  ShoppingCartOutlined,
  ShoppingBasketOutlined,
  HomeOutlined,
  InputOutlined,
  PersonAddOutlined,
} from "@material-ui/icons";
import React from "react";
import { useSelector } from "react-redux";
import {
  SidebarContainer,
  Icon,
  CloseIcon,
  SidebarWrapper,
  SidebarMenu,
  SidebarLinks,
} from "./SidebarElements";

const Sidebar = ({ isOpen, toggle }) => {
  const isAuth = useSelector((state) => state.auth?.isAuthenticated);
   const user = useSelector((state) => state.auth?.currentUser);
  const userAuthenticated = !isAuth ? (
    <SidebarContainer isOpen={isOpen} onClick={toggle}>
      <Icon onClick={toggle}>
        <CloseIcon style={{ marginRight: "30px" }} />
      </Icon>
      <SidebarWrapper>
        <SidebarMenu>
          <SidebarLinks to="/" onClick={toggle} duration={1000} exact="true">
            <HomeOutlined style={{ margin: "10px" }} />
            Go to Home
          </SidebarLinks>
          <SidebarLinks
            to="/products"
            onClick={toggle}
            duration={1000}
            exact="true"
          >
            <ShoppingBasketOutlined style={{ margin: "10px" }} />
            Go to Products
          </SidebarLinks>
          <SidebarLinks
            to="/login"
            onClick={toggle}
            duration={1000}
            exact="true"
          >
            <InputOutlined style={{ margin: "10px" }} />
            Go to Login
          </SidebarLinks>
          <SidebarLinks
            to="/register"
            onClick={toggle}
            duration={1000}
            exact="true"
          >
            <PersonAddOutlined style={{ margin: "10px" }} />
            Go to Register
          </SidebarLinks>
        </SidebarMenu>
      </SidebarWrapper>
    </SidebarContainer>
  ) : (
    <SidebarContainer isOpen={isOpen} onClick={toggle}>
      <Icon onClick={toggle}>
        <CloseIcon style={{ marginRight: "30px" }} />
      </Icon>
      <SidebarWrapper>
        <SidebarMenu>
          <SidebarLinks to="/" onClick={toggle} duration={1000} exact="true">
            <HomeOutlined style={{ margin: "10px" }} />
            Go to Home
          </SidebarLinks>
          <SidebarLinks
            to="/products"
            onClick={toggle}
            duration={1000}
            exact="true"
          >
            <ShoppingBasketOutlined style={{ margin: "10px" }} />
            Go to Products
          </SidebarLinks>
          <SidebarLinks
            to={`/profile/${user._id}`}
            onClick={toggle}
            duration={1000}
            exact="true"
          >
            <AccountBoxOutlined style={{ margin: "10px" }} />
            Go to Profile
          </SidebarLinks>
          <SidebarLinks
            to="/cart"
            onClick={toggle}
            duration={1000}
            exact="true"
          >
            <ShoppingCartOutlined style={{ margin: "10px" }} />
            Go to Cart
          </SidebarLinks>
        </SidebarMenu>
      </SidebarWrapper>
    </SidebarContainer>
  );
  return userAuthenticated;
};

export default Sidebar;
