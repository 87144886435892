import styled from "styled-components";
import { mobile } from "../../responsive";
export const AnnouncementContainer = styled.div`
  height: 30px;
  background-color: #e14a89;
  color: #fff;
  display: flex;
  align-items: center;
  justify-content: center;
  font-size: 14px;
  font-weight: bold;
  ${mobile({ display: "none" })}
`;
