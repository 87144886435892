import { ArrowLeftOutlined, ArrowRightOutlined } from "@material-ui/icons";
import React, { useEffect, useRef, useState } from "react";
import {
  SliderContainer,
  SliderWrapper,
  Arrow,
  ImageContainer,
  InfoContainer,
  Image,
  Slide,
  Title,
  Description,
  Button,
} from "./SliderElements";
import { sliderItems } from "../../data";
import { Link } from "react-router-dom";

const Slider = () => {
  const [slideIndex, setSlideIndex] = useState(0);
  const sliderRef = useRef();

  useEffect(() => {
    const intervalId = setInterval(() => {
      setSlideIndex((slideIndex) => (slideIndex + 1) % sliderItems.length);
    }, 8000);
    return () => clearInterval(intervalId);
  }, []);
  
  const handlePreviousClick = () => {
    setSlideIndex(
      (slideIndex) => (slideIndex - 1 + sliderItems.length) % sliderItems.length
    );
  };

  return (
    <SliderContainer>
      <Arrow direction="left" onClick={handlePreviousClick}>
        <ArrowLeftOutlined />
      </Arrow>
      <SliderWrapper slideIndex={slideIndex} ref={sliderRef}>
        {sliderItems.map((item) => (
          <Slide bg={item.bg} key={item.id}>
            <ImageContainer>
              <Image src={item.img} alt="img" />
            </ImageContainer>
            <InfoContainer top={item.top} left={item.left} right={item.right}>
              <Title>{item.title}</Title>
              <Description>{item.desc}</Description>
              <Link to={`/products`}>
                <Button color={item.color} border-color={item.color}>
                  IR À COMPRA
                </Button>
              </Link>
            </InfoContainer>
          </Slide>
        ))}
      </SliderWrapper>
      <Arrow
        direction="right"
        onClick={() =>
          setSlideIndex((slideIndex) => (slideIndex + 1) % sliderItems.length)
        }
      >
        <ArrowRightOutlined />
      </Arrow>
    </SliderContainer>
  );
};

export default Slider;
