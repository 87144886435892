import { Link } from "react-router-dom";
import styled from "styled-components";
import { mobile, tablet } from "../../responsive";

export const SliderContainer = styled.div`
  width: 100%;
  height: 100vh;
  display: flex;
  position: relative;
  overflow: hidden;
  ${mobile({ display: "none" })}
  ${tablet({ height: "80vh" })}
`;

export const Arrow = styled.div`
  width: 50px;
  height: 50px;
  background-color: #aca9a9;
  border-radius: 50%;
  display: flex;
  align-items: center;
  justify-content: center;
  position: absolute;
  top: 0;
  bottom: 0;
  left: ${(props) => props.direction === "left" && "10px"};
  right: ${(props) => props.direction === "right" && "10px"};
  margin: auto;
  cursor: pointer;
  opacity: 0.5;
  z-index: 2;
`;
export const SliderWrapper = styled.div`
  height: 100%;
  display: flex;
  transition: ease-in-out;
  transform: translateX(${(props) => props.slideIndex * -100}vw);
`;

export const Slide = styled.div`
  width: 100vw;
  height: 100vh;
  flex: 1;
  position: relative;
  background-color: ${(props) => props.bg};
  ${tablet({ height: "60vh" })}
`;
export const ImageContainer = styled.div`
  flex: 1;
`;
export const Image = styled.img`
  width: 100%;
  height: 100%;
  object-fit: cover;
`;
export const InfoContainer = styled.div`
  text-align: center;
  top: ${(props) => props.top};
  left: ${(props) => props.left};
  right: ${(props) => props.right};
  position: absolute;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
`;

export const Title = styled.h1`
  font-size: 50px;
  ${tablet({ fontSize: "20px" })}
`;

export const Description = styled.p`
  margin: 50px 0px;
  font-size: 20px;
  font-weight: 500;
  letter-spacing: 3px;
  ${tablet({ fontSize: "10px", margin: "15px 0px" })}
`;
export const Button = styled.button`
  padding: 10px;
  font-size: 20px;
  cursor: pointer;
  ${tablet({ fontSize: "10px" })}
`;

export const BL = styled(Link)`
  text-decoration: none;
  color: #000;
`;
