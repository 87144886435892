import { useEffect, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import { useLocation } from "react-router";
import { configHeaders, userRequest } from "../../requestMethods";
import { clearCart } from "../../redux/reducers/cartReducer";
import { Container, Wrapper, Svg, Title, Button, IL } from "./SuccessElement";
import { Check, Close } from "@material-ui/icons";

const Success = () => {
  const dispatch = useDispatch();
  const location = useLocation();
  const data = location.state?.stripeData;
  const products = location.state?.products;
  const total = location.state?.total;
  const currentUser = useSelector((state) => state.auth?.currentUser);
  const token = useSelector((state) => state.auth?.currentUser.token);
  const [orderId, setOrderId] = useState(null);

  useEffect(() => {
    const createOrder = async () => {
      try {
        const res = await userRequest.post(
          "/orders",
          {
            userId: currentUser._id,
            products: products.map((item) => ({
              productId: item._id,
              quantity: item.quantity,
            })),
            amount: total,
            address: data.billing_details.address,
            receipt: data.receipt_url,
            status: data.status,
          },
          configHeaders(token)
        );
        setOrderId(res.data._id);
      } catch {}
    };
    data && createOrder();
  }, [products, data, currentUser, token, total]);
  dispatch(clearCart());

  return (
    <Container>
      <Wrapper>
        {data && products && total ? (
          <Svg type="success">
            <Check style={{ color: "#9abc66" }} type="success" />
          </Svg>
        ) : (
          <Svg>
            <Close style={{ color: "red" }} type="failure" />
          </Svg>
        )}
        {data && products && total ? (
          <Title style={{ color: "#9abc66", background: "#f8faf5" }}>
            Success!
          </Title>
        ) : (
          <Title style={{ color: "red" }}>Failure!</Title>
        )}
        {orderId ? (
          <>
            <p>Your order is being prepared...</p>
            <a href={data.receipt_url} target="_blank" rel="noreferrer">
              Receipt
            </a>
          </>
        ) : (
          <p>An error occurred contact us via email!</p>
        )}

        {data && products && total ? (
          <Button type="success">
            <IL type="success" to="/">
              Go to Homepage
            </IL>
          </Button>
        ) : (
          <Button>
            <IL to="/">Go to Homepage</IL>
          </Button>
        )}
      </Wrapper>
    </Container>
  );
};

export default Success;
