import React from "react";
import { SearchOutlined } from "@material-ui/icons";

import { ProductContainer, Circle, Info, Image, Icon } from "./ProductElements";
import { Link } from "react-router-dom";

const Product = ({ item }) => {
  return (
    <ProductContainer>
      <Circle />
      <Image src={item.image} />
      <Info>
        <Icon>
          <Link to={`/product/${item._id}`}>
            <SearchOutlined />
          </Link>
        </Icon>
      </Info>
    </ProductContainer>
  );
};

export default Product;
